// Application Shell
import {Shell} from '@/entryPoints/b2c/shell';

// Vue Components
import LocatorContainer from '@/components/locator/LocatorContainer';
import ImageComponent from '@/components/ImageComponent';

import '@sassRoot/entryPoints/b2c/store-finder-v2.scss';

class StoreFinderV2 extends Shell {
  loadPlugins() {
    // Load common plugins from Shell
    super.loadPlugins();
  }

  preLoadInits() {
    super.preLoadInits();

    window.addEventListener('init-vue-dynamic-content', () => {
      const domElements = document.querySelectorAll('[data-vue-dynamic-content]');
      if (!domElements || !domElements.length) {
        return;
      }

      domElements.forEach((el) => {
        new this.vue({
          el: el,
          components: {
            ImageComponent,
          },
        });
      });
    });
  }
}

const storeFinder = new StoreFinderV2({LocatorContainer});
storeFinder.preLoadInits();
storeFinder.initializeVue();

// Legacy foundation initialization

$(window).on('load', () => {
  $(document).foundation();
  storeFinder.globalDocumentReadyInits();
});

$(window).on('load', () => {
  storeFinder.globalOnLoadInits();
});
